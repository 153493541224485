import { Action } from "../action/action";
import { ActionType } from "../action-Types/index";
import { MapType } from "../../../pages/getabox/types/BoxSignupType";

export interface UserState {
  id?: number;
  isLoggedIn?: boolean;
  name?: string;
  first_name?: string;
  last_name?: string;
  profile_image?: string;
  background_image?: string;
  bio?: string;
  city?: string;
  country?: string;
  email?: string;
  site_url?: string;
  facebook_url?: string;
  instagram_url?: string;
  twitter_url?: string;
  linkedin_url?: string;
  pinterest_url?: string;
  otherUrl_url?: string;
  mapView?: string;
  create_at?: string;
  following: number;
  followBy: number;
  connection: number;
  position?: string;
  customer_id?: string;
}

export const initialUserState: UserState = {
  isLoggedIn: false,
  name: "",
  first_name: "",
  last_name: "",
  profile_image: "",
  background_image: "",
  bio: "",
  city: "",
  country: "",
  email: "",
  site_url: "",
  facebook_url: "",
  instagram_url: "",
  twitter_url: "",
  linkedin_url: "",
  pinterest_url: "",
  otherUrl_url: "",
  following: 0,
  followBy: 0,
  connection: 0,
  position: "",
  mapView: MapType.DEFAULT,
  customer_id: "",
};

const userReducer = (state = initialUserState, action: Action) => {
  switch (action.type) {
    case ActionType.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case ActionType.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case ActionType.REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case ActionType.REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case ActionType.LOGOUT:
      return initialUserState
    case ActionType.SETUSER:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.UPDATEUSER:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.UPDATEMAPVIEW:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;

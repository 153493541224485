import { UserState } from "store/user/reducer/reducer";
import { BoxOverlayType } from "../../getabox/types/BoxSignupType";
import { type } from "os";
import { UserType } from "pages/NEW_dashboard/types/boxTypes";
import { photolikeTypes } from "../components/PhotosPanel";

export interface postType {
  id: number;
  images: string;
  post_id: number;
}

export interface CartDataProps {
  box: PhotosType;
  handlePhotoDelete: (photoID: number) => void;
}
export interface PhotosType {
  photoID: number;
  images: string;
  post_url: string;
  post_id: string;
}

export interface postObjType {
  postID: number;
  title?: string;
  description?: string;
  any_link?: string;
  youtube_url?: string;
  credit?: string;
  created_at?: string;
  updated?: string;
  deleted_at?: string;
  photos?: PhotosType[];
  images: string;
  post_id: number;
  posted_by: any;
  is_baned: boolean;
}

export interface favDataDict {
  PHOTO: any[];
  BOX: any[];
  POST: any[];
}

export interface AllDataType {
  allData: any[];
  posts?: any[];
  photos?: PhotosType[];
  boxes?: any[] | BoxDataType[];
  messages?: any[];
  connection?: any[];
  userPhotos?: any[];
  userPosts?: any[];
  favPosts?: any[];
  favData?: favDataDict;
  favPhotos?: any[];
  favBoxes?: any[];
  messagesReceived?: any[];
  messagesSend?: any[];
  followedBy?: any[];
  following?: any[];
  connectionAllData?: any[];
  count?: number;
}

export interface showOverlayTypes {
  data?: AllDataType;
  allFav?: any;
  loading?: boolean;
}

export interface BoxSidebarPropsType {
  setContentPanel?: (flag: BoxOverlayType) => void;
  data: AllDataType | undefined;
  contentPanel: string;
}

export interface likesTypes {
  post_id: number;
  like: number;
}

export interface photolike {
  photo_id: number;
  photolike: number;
}

export interface alltypes {
  currentPosts?: any;
  currentPhotos?: any;
  currentMessage?: any;
  currentConnection?: any;
}

export interface Comment {
  comment_id: number;
  comment_type: string;
  comment_by: number;
  comment: string;
  commented_at: string | null;
  deleted_at: string | null;

  id?: number;
  subcomment_count?: number;
  likes?: number;
  islike?: number;
  commentByName: string;
  commentByProfImg: string | null;
}

export interface getCommentApiResponse {
  data: Comment[] | [];
  count: number;
  status: number;
  message: string;
  success: boolean;
}
export interface SubComment {
  comment: number;
  id?: number;
  replied_at?: string | null;
  replied_by?: number | null;

  reply: string;

  deleted_at: string | null;
}

export interface SubCommentDataType {
  comment: number;
  id: number;
  replied_at: string | null;
  replied_by: number | null;
  islike?: boolean;
  likes?: number;
  subcommentByProfImg?: string;
  subcommentByName?: string;
  reply: string;
  deleted_at: string | null;
}

export interface SubCommentApiResponse {
  data: SubCommentDataType[] | [];
  count: number;
  status: number;
  message: string;
  success: boolean;
}

export interface commentTypes {
  id?: number;
  comment_id: number;
  comment?: string;
  comment_type?: string;
  alltypes?: any;
  comment_by?: number;
  currentPhotos?: any;
  currentMessage?: any;
  currentPosts?: any;
  user?: UserState;
}

export interface nameData {
  type: string;
  boxId: Number;
  box_name: string;
}

export interface descriptionData {
  type: string;
  boxId: Number;
  box_description: string;
}

export interface imageData {
  type: string;
  boxId: Number;
  image?: File | string | null;
}

export enum like_values {
  MEH = -1,
  YEAH = 1,
  COOL = 3,
  WOW = 5,
}

export enum like_names {
  MEH = "MEH",
  YEAH = "YEAH",
  COOL = "COOL",
  WOW = "WOW",
}

export enum popupOverlayType {
  POST = "POST",
  PHOTOS = "PHOTOS",
  MESSAGE = "MESSAGE",
  ALL = "ALL",
  BOX = "BOX",
}

export interface favTypes {
  fav_id: number;
  favorite: string;
}

export interface messageType {
  senderID: number;
  recieverID: number;
  message_text?: string;
}

export interface sendRequestTypes {
  user_sender?: number;
  user_reciever?: number;
}

export interface updateRequestTypes {
  isaccept?: boolean;
}

export interface sendFollowRequestTypes {
  followed_by?: number;
  following?: number;
}

export enum FavoriteType {
  POST = "POST",
  PHOTO = "PHOTO",
  BOX = "BOX",
}

export interface favTypeResponse {
  id?: number;
  fav_id: number;
  favorite: string;
  user: number;
  created_at?: string;
}

export enum SidePanelPage {
  DEFAULT = "DEFAULT",
  PHOTOS = "PHOTOS",
  POSTS = "POSTS",
  ALLCOMMENTS = "ALLCOMMENTS",
  FAVDATA = "FAVDATA",
  FAVPOSTS = "FAVPOSTS",
  ALLMESSAGES = "ALLMESSAGES",
  NOTIFICATIONS = "NOTIFICATIONS",
  ALLCONNECTIONS = "  ",
  USERDATA = "USERDATA",
  BOXES = "BOXES",
  REMOVE_PHOTO = "REMOVE_PHOTO",
  ALLCART = "ALLCART",
  REWARDS = "REWARDS",
  BADGES = "BADGES",
  TRANSACTIONS = "TRANSACTIONS",
}

interface userPostType {
  id?: number;
  profile_image?: string;
  background_image?: string;
  first_name?: string;
  name?: string;
  bio?: string;
  city?: string;
  country?: string;
  site_url?: string;
  facebook_url?: string | null;
  instagram_url?: string | null;
  twitter_url?: string | null;
  linkedin_url?: string | null;
  pinterest_url?: string | null;
  otherUrl_url?: string;
  is_active?: boolean;
  following?: number;
  followBy?: number;
  connection?: number;
  position?: string;
  date_joined?: string;
  username?: string;
  bane_type?: string;
  customer_id?: string;
}

// interface Box {
//   boxID: number;
//   box_name: string;
//   box_image: string | null;
//   box_background_image: string | null;
//   latitude: number;
//   longitude: number;
//   box_description: string;
//   is_baned: boolean;
//   is_primary: boolean;
//   box_use_type: string;
//   box_visibility: string;
//   custom_url: string | null;
//   created_at: string;
//   visitor_count: number;
//   user_id: number;
//   type: number;
//   promo_code: string | null;
// }
// interface Photo {
//   photoID: number;
//   post_id: number;
//   photo_by: number;
//   images: string;
//   post_url: string | null;
//   created_at: string;
//   deleted_at: string | null;
//   photolikes: number[];
//   totalLikes: number | null;
//   is_baned: boolean;
// }

// interface Post {
//   postID: number;
//   title: string;
//   description: string;
//   any_link: string | null;
//   youtube_url: string | null;
//   select_box: Box[];
//   photos: Photo[];
//   posted_by: User;
//   likes: number[];
//   allBox: boolean;
//   totalLikes: number | null;
//   is_baned: boolean;
// }

interface BoxType {
  id: number;
  type: string;
  price: number;
}

// const boxDataArray: BoxDataType2[] = [
//   BoxDataType
// ];

export interface BoxDataType {
  boxID: number;
  box_name?: string;
  box_image?: null | string;
  box_background_image: string;
  latitude: string;
  longitude: string;
  box_description?: string;
  is_baned?: boolean;
  is_primary?: boolean;
  box_use_type?: string;
  box_visibility?: string;
  custom_url?: null | string;
  created_at?: string | null;
  visitor_count?: number;
  user_id?: UserType;
  type: BoxType |  number;
  promo_code?: null | string;
  filter?: string;
  total_distance?: number;
}
export interface BoxDataType2 {
  boxID?: number;
  box_name?: string;
  box_image?: null | string;
  box_background_image?: null | string;
  latitude: string | number;
  longitude: string | number;
  box_description?: string;
  is_baned?: boolean;
  is_primary?: boolean;
  box_use_type?: string;
  box_visibility?: string;
  custom_url?: null | string;
  created_at?: string | null;
  visitor_count?: number;
  user_id?: number;
  type: BoxType;
  promo_code?: null | string;
  filter?: string;
}
// interface BoxData {
//   boxID?: number;
//   user_id?: {
//     id?: number;
//     profile_image?: string;
//     background_image?: string;
//     first_name?: string;
//     name?: string;
//     bio?: string;
//     city?: string;
//     country?: string;
//     site_url?: string;
//     facebook_url?: string | null;
//     instagram_url?: string | null;
//     twitter_url?: string | null;
//     linkedin_url?: string | null;
//     pinterest_url?: string | null;
//     otherUrl_url?: string;
//     is_active?: boolean;
//     following?: number;
//     followBy?: number;
//     connection?: number;
//     position?: string;
//     date_joined?: string;
//     username?: string;
//     bane_type?: string;
//     customer_id?: string;
//   };
//   box_name?: string;
//   box_image?: null | string;
//   box_background_image?: null | string;
//   latitude?: number;
//   longitude?: number;
//   box_description?: string;
//   is_banned?: boolean;
//   is_primary?: boolean;
//   box_use_type?: string;
//   box_visibility?: string;
//   custom_url?: null | string;
//   created_at?: string | null;
//   visitor_count?: number;
//   type?: number;
//   promo_code?: null | string;
// }

export interface Photo {
  photoID?: number;
  post_id?: number;
  photo_by?: number;
  images?: string;
  post_url?: null | string;
  created_at?: string | null;
  deleted_at?: null | string;
  photolikes?: number[];
  totalLikes?: number;
  is_baned?: boolean;
}

export interface LikesForPostDataTypes {
  id: number;
  post_id: number;
  like: number;
  user: number;
}

export interface PostDataType {
  postID?: number;
  title?: string | null;
  description?: string | null;
  any_link?: null | string;
  youtube_url?: null | string;
  select_box?: BoxDataType2[] | [];

  credit?: string | null;
  created_at?: string | null;
  updated?: null | string;
  deleted_at?: null | string;
  photos: Photo[] | [];

  posted_by?: UserType;

  // id?: number;
  // profile_image?: string;
  // background_image?: string;
  // first_name?: string;
  // name?: string;
  // bio?: string;
  // city?: string;
  // country?: string;
  // site_url?: string;
  // facebook_url?: null | string;
  // instagram_url?: null | string;
  // twitter_url?: null | string;
  // linkedin_url?: null | string;
  // pinterest_url?: null | string;
  // otherUrl_url?: string;
  // is_active?: boolean;
  // following?: number;
  // followBy?: number;
  // connection?: number;
  // position?: string;
  // date_joined?: string;
  // username?: string;
  // bane_type?: string;
  // customer_id?: string;

  likes?: LikesForPostDataTypes[] | [];
  allBox?: boolean;
  totalLikes?: number | null;
  is_baned?: boolean;
}

export interface PostType {
  data: PostDataType[] | [];
  count: number;
  status: number;
  message: string;
  success: boolean;
}

interface FavoriteAllDataType {
  id: number;
  fav_id: number;
  favorite: "PHOTO" | "POST";
  created_at: string;
  user: number;
  box_id: number;
}

// // Assuming you have a component named `MyComponent`
// const MyComponent: React.FC<{ allData: FavoriteDataType[] }> = ({
//   allData,
// }) => {
//   // ... component logic using `allData`
// };

export interface AllDataType2 {
  allData: FavoriteAllDataType[];
  posts?: any[];
  photos?: PhotosType[];
  boxes?: any[];
  messages?: any[];
  connection?: any[];
  userPhotos?: any[];
  userPosts?: any[];
  favPosts?: any[];
  favData?: favDataDict;
  favPhotos?: any[];
  favBoxes?: any[];
  messagesReceived?: any[];
  messagesSend?: any[];
  followedBy?: any[];
  following?: any[];
  connectionAllData?: any[];
  count?: number;
}

export interface FavoriteAllType {
  data?: AllDataType2[];
  status?: number;
  message?: string;
  success?: boolean;
}

// interface PhotoData {
//   photoID: number;
//   post_id: number;
//   photo_by: number;
//   images: string;
//   post_url: null;
//   created_at: string;
//   deleted_at: null;
//   photolikes: [];
//   totalLikes: null;
//   is_baned: boolean;
// }

// export interface PostType {
//   data: PostDataType[];
//   count: number;
//   status: number;
//   message: string;
//   success: boolean;
// }

interface DataItem {
  id?: number;
  user_sender?: userPostType;
  user_reciever?: userPostType;
  created?: string;
  isaccept?: boolean;
}

export interface getRequestListObject {
  data: DataItem[];
  status?: number;
  message?: string;
  success?: boolean;
}

export interface UserBoxApiResponse {
  data: BoxDataType[] | [];
  status?: number;
  message?: string;
  success?: boolean;
}

interface Message {
  messageID: number;
  sender: UserType;
  receiver?: UserType;
  message_text?: string;
  created_at: string;
  deleted_at?: null | Date;
  is_read: boolean;
}

export interface MessageDataApiResponse {
  messageSendTo: Message[];
  messageRecievedFrom: Message[];
  msg: Message[];
  status: number;
  message: string;
  success: boolean;
}

export interface UserPhotosApiResponse {
  data: photolikeTypes[] | [];
  count: number;
  status: number;
  message: string;
  success: boolean;
}

export interface FriendRequestApiResponse {
  data: any;
  status: number;
  message: string;
}
// UserType;

interface FollowRequest {
  id: number;
  followed_by: UserType;
  following: UserType;
  created: string;
  deleted_at: string | null;
}

export interface FollowRequestApiResponse {
  data: FollowRequest[] | [];
  status: number;
  message: string;
  success: boolean;
}
